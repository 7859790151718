@tailwind base;
@tailwind components;
@tailwind utilities;
.ibm-plex-serif-medium {
  font-family: "IBM Plex Serif", serif;
  font-style: medium;
}

.ibm-plex-serif-light {
  font-family: "IBM Plex Serif", serif;
  font-style: light;
}



/* sans font */
.ibm-plex-sans-normal {
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
}

.ibm-plex-sans-mxied {
  font-family: "IBM Plex Sans", sans-serif;
  font-style: mixed;
}


/* inter font */
.inter-extraBold {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
}
